import React from "react";
import "./App.css";
import Terminal from "./components/Terminal";

const getYear = () => {
  return new Date().getFullYear();
};

const welcomeMessage = `Welcome cyber warriors. To the moon!

Type 'help' to view a list of available commands.
`;

const bannerCondensed =
    "        _..._               ______          __         ___    ____        \n" +
    "      .'     '.      _     / ____/___  ____/ /_  __   /   |  / / /__  ____ \n" +
    "     /    .-''-\\   _/ \\   / /   / __ \\/ __  / / / /  / /| | / / / _ \\/ __ \\\n" +
    "   .-|   /:.   |  |   |  / /___/ /_/ / /_/ / /_/ /  / ___ |/ / /  __/ / / /\n" +
    "   |  \\  |:.   /.-'-./   \\____/\\____/\\__,_/\\__, /  /_/  |_/_/_/\\___/_/ /_/ \n" +
    "   | .-'-;:__.'    =/                     /____/                           \n" +
    "  .'=  *=|     _.='                                                        \n" +
    " /   _.  |    ;                                                            \n" +
    ";-.-'|    \\   |                                                            \n" +
    "/   |  \\  _\\  _\\                                                       \n" +
    "\\__/'._;.  ==' ==\\                                                     \n" +
    "        \\    \\   |                                                      \n" +
    "        /    /   /                                                      \n" +
    "        /-._/-._/                                                        \n" +
    "        \\   `\\  \\                                                        \n" +
    "         `-._/._/                                                        \n" +
    "  \u00A9 " +
    getYear();

// "   ______          __         ___    ____         \n" +
// "  / ____/___  ____/ /_  __   /   |  / / /__  ____\n" +
// " / /   / __ \\/ __  / / / /  / /| | / / / _ \\/ __ \\\n" +
// "/ /___/ /_/ / /_/ / /_/ /  / ___ |/ / /  __/ / / /\n" +
// "\\____/\\____/\\__,_/\\__, /  /_/  |_/_/_/\\___/_/ /_/ \n" +
// "                 /____/                           \n" +
// "  \u00A9 " +
//   getYear();
  
const prompt = ">";

function App() {
  return (
    <Terminal
      welcomeMessage={welcomeMessage}
      banner={bannerCondensed}
      terminalPrompt={prompt}
    />
  );
}

export default App;
