import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import TerminalOutput from "./TerminalOutput";
import InputArea from "./InputArea";
import ErrorMessage from "./ErrorMessage";
import WelcomeMessage from "./WelcomeMessage";

// Just a little helper function so I don't have to continually update my age
const getAge = (birthDate: Date) => {
  var today = new Date();
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const downloadFile = (uri: string, downloadName: string) => {
  const link = document.createElement("a");
  link.download = downloadName;
  link.href = uri;
  link.click();
  link.remove();
};

type TerminalProps = {
  terminalPrompt?: string;
  banner?: string;
  welcomeMessage?: string;
};
const Terminal = (props: TerminalProps) => {
  const { terminalPrompt = ">", banner, welcomeMessage } = props;
  const [output, setOutput] = useState<(string | JSX.Element)[]>([]);
  const [history, setHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(3);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const scrollRef = React.useRef<HTMLDivElement | null>(null);

  const scrollLastCommandTop = () => {
    scrollRef.current?.scrollIntoView();
  };

  useEffect(scrollLastCommandTop, [output]);

  const echoCommands = [
    "help",
    "about",
    // "projects",
    "certifications",
    "repo",
    "skills",
    "contact",
  ] as const;
  type EchoCommand = typeof echoCommands[number];
  const utilityCommands = ["clear", "all", "resume"] as const;
  type UtilityCommand = typeof utilityCommands[number];
  const allCommands = [...echoCommands, ...utilityCommands] as const;
  type Command = typeof allCommands[number];

  function isEchoCommand(arg: string): arg is EchoCommand {
    return (echoCommands as ReadonlyArray<string>).includes(arg);
  }

  function isUtilityCommand(arg: string): arg is UtilityCommand {
    return (utilityCommands as ReadonlyArray<string>).includes(arg);
  }

  function isValidCommand(arg: string): arg is Command {
    return isEchoCommand(arg) || isUtilityCommand(arg);
  }

  const glow = (text: string) => {
    return <span className="terminal-glow">{text}</span>;
  };

  const commands: { [key in EchoCommand]: JSX.Element } = {
    help: (
      <div>
        {/* <p>
          Just type any of the commands below
          to get some more info. You can even type a few letters and press [tab]
          or '.' to autocomplete.
        </p> */}
        <dl>
          <dt>about</dt>
          <dd>Learn more about me</dd>
          {/* <dt>projects</dt>
          <dd>I've made some cool stuff</dd> */}
          <dt>skills</dt>
          <dd>I'm pretty good at some things</dd>
          <dt>certifications</dt>
          <dd>Proven expertise</dd>
          <dt>repo</dt>
          <dd>Take a look at some of my work</dd>
          <dt>resume</dt>
          <dd>Check out my Resume [pdf]</dd>
          <dt>contact</dt>
          <dd>Bring on the spam</dd>
          <dt>all</dt>
          <dd>Tell me everything</dd>
          <dt>clear</dt>
          <dd>Clears the terminal</dd>
        </dl>
      </div>
    ),
    about: (
      <div>
        {/* <p>
          Here is some info about me -
        </p> */}
        <p>
          My name is {glow("Cody Allen")}. I'm a{" "}
          {getAge(new Date(1994, 1, 15))} year old {glow("Cybersecurity Engineer")}.
        </p>
        <p>
          I graduated from Stockton University with a
          Bachelor of Science degree in Sustainability with a focus on Computer Science. It comprised
          of four years of science courses, as well as many computer science
          courses (for example, I completed three years of physics, economics, stats, and
          calculus).
        </p>
        <p>
          Some of my interests include: hacking everything, SIGINT, exploring the RF Spectrum with SDRs, DevSecOps, and leveraging these tools to help solve problems -
          particularly in the {glow("GovTech")} space. I'm also into cars & legos!
        </p>
        <p>
          My previous formal work experience includes:
          <ul>
            <li>
              working on space integration & ATC systems at the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.faa.gov"
              >
                FAA
              </a>
              .
            </li>
            <li>
              securing one of the largest banks in FinTech for{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.td.com"
              >
                TD Bank
              </a>
              .
            </li>
            <li>
              solving complex national security problems at{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://teamraft.com"
              >
                raft
              </a>
              .
            </li>
          </ul>
        </p>
        <p>
          Please feel free to get in touch with me to discuss any cool
          opportunities. My contact details can be found by typing 'contact',
          and if you would like to check out my {glow("Resume")}, simply type 'resume'
          or click{" "}
          <a href="Resume.pdf" download="Cody Allen Resume.pdf">
            here
          </a>
          .
        </p>
      </div>
    ),
    // projects: (
    //   <>
    //   </>
    // ),
    contact: (
      <>
        <dl>
          <dt>Email</dt>
          <dd>
            <a href="mailto:contact@codyallen.sh">contact@codyallen.sh</a>
          </dd>
          {/* <dt>Smoke signals</dt>
          <dd>USA</dd>
          <dt>TikTok</dt>
          <dd>just kidding</dd> */}
        </dl>
      </>
    ),
    certifications: (
      <>
           <dl>
          <dt>Security + </dt>
          <dd>CompTIA</dd>
          <dt>KCSA</dt>
          <dd>Linux Foundation</dd>
          <dt>KCNA</dt>
          <dd>Linux Foundation</dd>

           </dl>

      </>
    ),
    repo: (
      <>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/codyallenn"
            >
              GitHub
            </a>{" "}
            - Most projects are offline, on GitHub, or confidential.
          </li>
        </ul>
      </>
    ),
    skills: (
      <>
       <div className="terminal-heading">DevSecOps</div>
        <dl>
          <dt>Linux/Networking</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#00DE12", textShadow: "0 0 5px #00DE12" }}>
              #############
            </span>
            {"   "}
            ##
          </dd>
          <dt>CI/CD</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#5BD100", textShadow: "0 0 5px 5BD100" }}>
              ###########
            </span>
            {"     "}
            ##
          </dd>
          <dt>GitOps</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#99D100", textShadow: "0 0 5px #99D100" }}>
              ########
            </span>
            {"        "}
            ##
          </dd>
        </dl>

        <div className="terminal-heading">Cloud &amp; Infrastructure</div>
        <dl>
        <dt>AWS</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#00DE12", textShadow: "0 0 5px #00DE12" }}>
              ############
            </span>
            {"    "}
            ##
          </dd>
          <dt>Azure</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#99D100", textShadow: "0 0 5px 99D100" }}>
              ########
            </span>
            {"        "}
            ##
          </dd>
          <dt>GCP</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#99D100", textShadow: "0 0 5px #99D100" }}>
              #######
            </span>
            {"         "}
            ##
          </dd>
          <dt>
            Infrastructure <br />
            <span style={{ fontSize: "smaller" }}>
              (Kubernetes, Terraform, etc.)
            </span>
          </dt>
          <dd>
            ##{" "}
            <span style={{ color: "#00DE12", textShadow: "0 0 5px #00DE12" }}>
              ############
            </span>
            {"    "}
            ##
          </dd>
        </dl>

        <div className="terminal-heading">Languages</div>
        <dl>
          <dt>Go</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#00DE12", textShadow: "0 0 5px #00DE12" }}>
              ############
            </span>{"    "}
            ##
          </dd>
          <dt>Bash</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#00DE12", textShadow: "0 0 5px #00DE12" }}>
              ############
            </span>
            {"    "}
            ##
          </dd>
          <dt>Python</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#42D100", textShadow: "0 0 5px #42D100" }}>
              ###########
            </span>
            {"     "}
            ##
          </dd>
          <dt>Java</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#42D100", textShadow: "0 0 5px #42D100" }}>
              ###########
            </span>
            {"     "}
            ##
          </dd>
          <dt>TypeScript</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#99D100", textShadow: "0 0 5px #99D100" }}>
              ########
            </span>
            {"        "}
            ##
          </dd>
          <dt>Rust</dt>
          <dd>
            ##{" "}
            <span style={{ color: "#D16200", textShadow: "0 0 5px #D16200" }}>
              #####
            </span>
            {"           "}
            ##
          </dd>
        </dl>
      </>
    ),
  };

    const processCommand = (input: string) => {
    
    // Store a record of this command with a ref to allow us to scroll it into view.
    // Note: We use a ref callback here because setting the ref directly, then clearing output seems to set the ref to null.

    // Store a record of this command with a ref to allow us to scroll it into view.
    // Note: We use a ref callback here because setting the ref directly, then clearing output seems to set the ref to null.
    const commandRecord = (
      <div
        ref={(el) => (scrollRef.current = el)}
        className="terminal-command-record"
      >
        <span className="terminal-prompt">{terminalPrompt}</span>{" "}
        <span>{input}</span>
      </div>
    );

    // Add command to to history if the command is not empty
    if (input.trim()) {
      setHistory([...history, input]);
      setHistoryIndex(history.length + 1);
    }

    // Now process command, ignoring case
    const inputCommand = input.toLowerCase();
    if (!isValidCommand(inputCommand)) {
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">
          <ErrorMessage command={inputCommand} />
        </div>,
      ]);
    } else if (isEchoCommand(inputCommand)) {
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">{commands[inputCommand]}</div>,
      ]);
    } else if (isUtilityCommand(inputCommand)) {
      switch (inputCommand) {
        case "clear": {
          setOutput([]);
          break;
        }
        case "all": {
          // Output all commands in a custom order.
          const allCommandsOutput = [
            "about",
            "certifications",
            "skills",
            // "projects",
            "repo",
            "contact",
          ].map((command) => (
            <>
              <div className="terminal-heading">{command}</div>
              <div className="terminal-command-output">
                {commands[command as EchoCommand]}
              </div>
            </>
          ));

          setOutput([commandRecord, ...allCommandsOutput]);
          break;
        }
        case "resume": {
          setOutput([...output, commandRecord]);
          downloadFile("Resume.pdf", "Cody Allen Resume.pdf");
          break;
        }
      }
    }
  };

  const getHistory = (direction: "up" | "down") => {
    let updatedIndex;
    if (direction === "up") {
      updatedIndex = historyIndex === 0 ? 0 : historyIndex - 1;
    } else {
      updatedIndex =
        historyIndex === history.length ? history.length : historyIndex + 1;
    }
    setHistoryIndex(updatedIndex);
    return updatedIndex === history.length ? "" : history[updatedIndex];
  };

  const getAutocomplete = (input: string) => {
    const matchingCommands = allCommands.filter((c) => c.startsWith(input));
    if (matchingCommands.length === 1) {
      return matchingCommands[0];
    } else {
      const commandRecord = (
        <div
          ref={(el) => (scrollRef.current = el)}
          className="terminal-command-record"
        >
          <span className="terminal-prompt">{terminalPrompt}</span>{" "}
          <span>{input}</span>
        </div>
      );
      setOutput([...output, commandRecord, matchingCommands.join("    ")]);
      return input;
    }
  };

  const focusOnInput = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Tab") {
      // Prevent tab from moving focus
      event.preventDefault();
    }
    inputRef.current?.focus();
  };

  return (
    <div className="terminal-container" tabIndex={-1} onKeyDown={focusOnInput}>
      <div className="terminal-content">
        {banner && <Banner banner={banner} />}
        {welcomeMessage && (
          <WelcomeMessage message={welcomeMessage} inputRef={inputRef} />
        )}
        <TerminalOutput outputs={output} />
        <InputArea
          setOutput={setOutput}
          processCommand={processCommand}
          getHistory={getHistory}
          getAutocomplete={getAutocomplete}
          inputRef={inputRef}
          terminalPrompt={terminalPrompt}
        />
      </div>
    </div>
  );
};

export default Terminal;
